$primary_color: #003950;
$secondary_color: #00638C;
$body_text_color: #4A4A4A;
$alternate_color: #00AB67;
$light_blue: #009BDB;
$dark_gray: #727575;
$light_gray: #9EA0A0;

$global_font: 'Nunito Sans', sans-serif;
$header_font: 'Nunito Sans', sans-serif;
$brand_font_1: 'Nunito Sans', sans-serif;
$brand_font_2: 'adobe-caslon-pro', serif;
