@import "_variables";

html{
  overflow-x:hidden;
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
.gfield_select{
  height: 38px!important;
  background-color: #FFFFFF!important;
  border-radius: 0!important;

}
body{
    font-family: 'Nunito Sans', sans-serif!important;
    overflow-x: hidden;
}
h1{
  font-size: 3rem;
  font-weight: 900;
  @media(max-width: 767px){
    font-size: 2.5rem;
  }
}
h2{
  font-size: 2.7rem;
  font-weight: 900;
  @media(max-width: 767px){
    font-size: 2.1rem;
  }
}
h3{
font-size: 1.6rem;
  font-weight: 900;
  @media(max-width: 767px){
    font-size: 1.3rem;
  }
}
.title-image-back{
  height: auto;
  .tag{
    position: relative;
    max-width: 100%;
    left: 0;
    bottom: auto;
    padding: 50px 0;
    h1{
      font-size: 3rem;
      color: $primary_color;
    }
  }
}
p{
  font-size: 1.1rem;
  @media(max-width: 767px){
    font-size: 1rem;
  }
}

.pl0{
  padding-left: 0;
}
.pr0{
  padding-left: 0;
}
.light-gray{
  color: $light_gray;
}
.dark-gray{
  color: $dark_gray;
}
.green{
  color: $alternate_color;
}
.map-section{
  @media(min-width: 992px){
    max-height: 515px;
    overflow: hidden;
  }
  .green-content-block{
    background-color: $alternate_color;
    padding: 50px 20% 50px 5%;
    height: 100%;
    float: right;
    @media(max-width: 991px){
      padding: 50px;
      .content{
        h2,p{
          text-align: center;
        }
        max-width: 100%!important;
      }
    }
    .content{
      max-width: 85%;
      float: right;
      .action-buttons{
        float: right;
        @media(max-width: 991px){
          text-align: center;
          width: 100%;
        }
        .btn{
          span{
            padding: 11px 15px 11px 15px!important;
          }
        }
      }
    }
    h2{
      color: #FFFFFF;
      text-align: right;
    }
    p{
      font-size: 1.3rem;
      color: $primary_color;
      letter-spacing: 0;
      text-align: right;
      line-height: 1.6rem;
    }

  }
}

hr{
  border-top: 3px #D9DBDB solid;
}

.no-margin-grid-block{
  @media(max-width: 767px){
    height: 55vh!important;
    min-height: 400px;
  }
}
#navCTA{
  @media(min-width: 992px){
    float: right!important;
    position: absolute;
    right: 0px;
    top: 15px;
  }
  @media(max-width: 991px){
    display: block;
    margin-top: 15px;

  }
}
.breadcrumbs-bar{
  background-color: $primary_color;
  .container{
    padding: 10px 15px;
    color: #FFF;
  }
  span.separator{
    padding: 0 10px;
    font-size: .9rem;
  }
  a{
    span{
      font-size: .9rem;
      text-transform: uppercase;
      color: $alternate_color;
      font-weight: 700;
    }
  }
  span{
    color: #D9DBDB;
    font-size: .9rem;
  }
}

.btn{
  border-radius: 0;
  padding: 0;
  border-width: 3px;
  text-transform: uppercase;
  span{
    padding: 14px 15px 12px 15px;
    float: left;
    font-size: 1rem;
    line-height: 1;
  }
  &:active{
    box-shadow: none!important;
    -webkit-box-shadow:none!important;
  }
  &:focus{
    outline:none;
    outline-offset: 0;
  }
  &.left-arrow:after{
    content:"\f100"!important;
  }
  &.right-arrow:after{
    content:"\f101"!important;
  }
  &.file:after{
    content: "\f1c1"!important;
  }
  &:after{
    content: "\f101";
    font-size: 1.8rem;
    font-family: "FontAwesome";
    float: right;
    height: 100%;
    font-family: FontAwesome;
    float: right;
    color: #FFF;
    padding: 0px 15px;
  }
  &.btn-transparent-green{
    color: $alternate_color!important;
    border-color: $alternate_color;
    &:hover, &:active, &:focus{
      color: #FFF!important;
    }
    &.hvr-sweep-to-right:before{
      background: $alternate_color;
    }
    &:after{
      background-color: $alternate_color;
      border: 3px $alternate_color solid;
    }
  }
  &.btn-transparent-blue{
    color: $light_blue;
    border-color: $light_blue;
    &:hover, &:active, &:focus{
      color: #FFF;
    }
    &.hvr-sweep-to-right:before{
      background: $light_blue;
    }
    &:after{
      background-color: $light_blue;
      border: 3px $light_blue solid;
    }
  }
  &.btn-transparent-white{
    &.hvr-sweep-to-right:before{
      background: #FFF;
    }
    &:hover, &:active, &:focus{
      background-color: rgba(255,255,255,.85)!important;
      span, &:after{
        color: $dark_gray;
      }

    }
    &:after{
      background-color: transparent;
      border-left: 3px #FFF solid;
    }
  }

  &.btn-white-on-blue{
    background-color: $light_blue;
    color: #FFFFFF;
  }

}

.featured-projects .btn-transparent-white, .recent-posts .btn-transparent-white{
  z-index: 50;
  &:after{
    border-left: 0!important;
  }
}

.top-bar{
  background-color: #D9DBDB;
  padding:8px;
  text-align: right;
  margin-bottom: 10px;
  @media(max-width:767px){
    text-align: center;
  }
  p, a{
    color: #00638C!important;
    font-weight: bold;
    font-size: 1rem;
  }
}
nav.navbar.bootsnav{
  ul.nav{
    @media(min-width: 992px){
      margin-right: 170px;
    }
    li{
      a{
        color: $alternate_color;
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
  }
}

.full-screen-billboard, .full-screen-billboard-video{

  &.dark{
    h2{
      color: $primary_color;
      font-size: 2.8rem!important;
    }
    h3{
      font-size: 1.7rem!important;
      margin-top: 0;
    }
  }
  h2{
    color: $primary_color;
    font-size: 2.8rem!important;
  }
  h3{
    font-size: 1.7rem!important;
    line-height: 2.2rem;
    margin-top: 0;
    font-weight: 700;
    color: $body_text_color;
  }
  @media(max-width: 767px){
    .video-content-container{
      padding-top: 20vh!important;
    }
  }
  .content-container{
    width: 66%;
    background: rgba(255,255,255,0.92);
    padding: 25px 0;
    border-top: 4px $alternate_color solid;
    @media(max-width: 767px){
      width: 90%!important;
      padding: 25px 15px;
      h2{
        font-size: 2rem!important;
      }
      h3{
        margin-top: 5px!important;
        font-size: 1.3rem!important;
      }
    }
  }
}


.half-width-content-image-bg{

   .content-container{
     @media(max-width: 767px){
       padding: 20px;
     }
     @media(min-width: 768px){
       /*background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.92) 5%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.92) 5%,rgba(255,255,255,1) 100%);
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.92) 5%,rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );*/
padding: 40px 12% 40px 15%;
     }
     h2,p{
       @media(min-width: 768px){
        }
     }
     p{
       margin: 25px 0;
     }
   }
}

.home, .page-id-2501{
  .featured-projects{
      background-color: #D9DBDB;
  }
  .product-block{
    &.active{
      .product-link{
        background-color: $primary_color;
      }
      img{
        opacity: 0.9;
      }
    }
  }

  .product-link{
    background-color: $secondary_color;
    border-top: 3px $light_blue solid;
    color: #FFFFFF;
    width: 100%;
    padding: 10px;
    display: block;
    text-align: center;
    &:hover{
      text-decoration: none;
    }
  }
}

.project-preview{
  margin-bottom: 25px;
}
.featured-projects{
  padding: 50px 0;
  .no-margin-grid-block{
    & > a{
      position: absolute;
      z-index: 50;
      height: 100%;
      width: 100%;
    }
    &.active{
      .block{
        &.has_link{
          background: rgba(0,0,0,.75);
        }
        .btn{
          span{

          }
          &:after{
            color:#727575;
          }
          &:before{
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }
      }
    }
    .block{
      padding: 0;
      .category{
        display: block;
        color: $light_blue;
        font-size: 1.1rem;
        text-transform: uppercase;
      }
      h3,p{
        color: #FFF!important;
        background-color: transparent;
        padding: 0 10px 0 0;
      }
      h3{
        @media(max-width: 767px){
          font-size: 1.5rem;
        }
      }
      p{
        font-size: 1.1rem;
      }

      &.has_link{
       background: rgba(0,0,0,.55);
      }
      .content{
        padding: 25px;
        top:30px;
        bottom: auto;
        height: 90%;
      }
      /*.btn{
        position: absolute;
        bottom: 20px;
        @media(max-width: 767px){
          bottom: 0px!important;
        }
      }*/
      .btn{
        span{
          padding: 0;
        }
      }
    }
  }
}

.recent-posts{
  .no-margin-grid-block{
    .link{
      &:hover{
        text-decoration: none;
      }
    }
    &.active{
      .block{
        &.has_link{
          background: rgba(0,0,0,.55);
        }
        .btn{
          span{

          }
          &:after{
            color:#727575;
          }
          &:before{
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }
      }
    }
    .block{
      &.has_link{
        background: transparent;
        &:hover{
          background: rgba(0,0,0,0.25);
          cursor: pointer;
        }
      }
    }

    &:first-child{
      background-color: $primary_color!important;
    }
    &:nth-child(2){
      background-color: $dark_gray!important;
    }
    &:nth-child(3){
      background-color: $light_blue!important;
    }
    .content{
      padding: 25px;
      top:30px;
      bottom: auto;
      height: 90%;
    }
    h3,p{
      background-color: transparent;
      padding: 0 10px 0 0;
    }
    h3{
      font-size: 1.6rem;
    }
    p{
      font-size: 1rem;
    }
    /*.btn{
      position: absolute;
      bottom: 20px;
      @media(max-width: 767px){
        bottom: 0px!important;
      }
    }*/
    .btn{
      span{
        padding: 0;
      }
    }
  }
}

.page-cta{
  .content{
    border-top:3px #FFF solid;
    margin: 50px 0;
    background-color: rgba(0,171,103,0.87);
    padding: 25px 150px;
    @media(max-width: 767px){
      padding: 25px 35px;
    }
    h3{
      font-family: 'Nunito Sans', sans-serif!important;
      font-size: 2.4rem!important;
      color: #FFFFFF!important;
      letter-spacing: 0;
      text-align: center;
      line-height: 3rem!important;
      font-weight: 900;
    }
    p{
      font-family: 'Nunito Sans', sans-serif!important;
      font-size: 1.3rem;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.5rem;
      font-weight: 700;
    }
    .btn{
      span{
        padding: 13px 15px 11px 15px;
        @media(max-width: 767px){
          padding: 12px 15px 11px 15px;
        }
      }
    }
    h3,p{
      color: #FFFFFF;
    }
  }
}

.project-cat-links{
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
  li{
    display: inline;
    padding-right:15px;
    float: left;
    @media(max-width: 767px){
      width: 100%;
    }
    a{
      color: #727575;
      font-size: 1.1rem;
      float: left;
      &.active{
        color: #009BDB;
      }
      span{
        font-size: 1.6rem;
        line-height: 1.5rem;
        float: right;
      }
    }
  }
}

.project-archive{
  h2, .jump-to-cat{
    color: $primary_color;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 900;
  }
  h3{
    color: $secondary_color;
  }
  .read-more{
    color: $light_blue;
    font-weight: 700;
    i{
      font-size: 1.6rem;
      line-height: 1.1;
    }
  }
}

#projectCarousel{
  .carousel-control{
    background-image: none;
  }
  .btn:after{
    content: '';

  }
  .item{
    img{
      margin: auto;
    }
  }
  .carousel-buttons{
    position: absolute;
    bottom: 25px;
    right: 15%;
    .btn:active{
      background-image: none!important;
      box-shadow: none!important;
    }
  }
}

.staff-card{
  background-color: #FFFFFF;
  padding: 25px;
  margin:25px 0;
  border-top:3px $alternate_color solid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  &.default-staff-card{
    h2{
      color: $alternate_color;
      font-size: 1.5rem;
    }
    h3{
      font-size: 1.1rem;
    }
  }
  img{
    border: 3px $light_blue solid;
  }
  .name{
    margin: 5px 0;
  }
  .contact{
    color: $alternate_color;
    font-style: italic;
    font-weight: 600;
    font-size: 1.1rem;
    &:after{
      padding-left: 5px;
      content: "\f101";
      font-size: 1.1rem;
      font-family: "FontAwesome";
    }
  }
}

.single-project{
  .quote{
    margin: 75px 0;
    text-align: center;
    font-size: 1.7rem;
    color: $light_blue;
    font-style: italic;
    font-weight: 700;
  }

  .products-used{
    .products{
      padding: 25px 0;
      .product-link{
        background-color: $secondary_color;
        border-top: 3px $light_blue solid;
        color: #FFFFFF;
        width: 100%;
        padding: 10px;
        display: block;
        text-align: center;
      }
    }
  }
}
.project-navigation{
  margin: 0 0 25px 0;
  a{
    color: $light_blue;
    font-size: 1.1rem;
  }
}

.products-grid{
  .product-block{
    &.active{
      .product-link{
        background-color: $primary_color;
      }
      img{
        opacity: 0.9;
      }
    }
  }

  .product-link{
    background-color: $secondary_color;
    border-top: 3px $light_blue solid;
    color: #FFFFFF;
    width: 100%;
    padding: 10px;
    display: block;
    text-align: center;
    &:hover{
      text-decoration: none;
    }
  }
  .row{
    margin-bottom: 40px;
  }
}

.single-product{
  margin:50px 0;
  h1{
    font-weight:900;
  }
  .spec-link{
    display: block;
    color: $light_blue;
    font-size: .9rem;
  }
  .product-cta{
    background-color: #003950;
    padding: 15px;
    @media(min-width: 768px){
    padding: 15px 25px 15px 15px;
    }
    h3{
      font-size: 2rem!important;
      line-height: 2.5rem!important;

      @media(min-width: 768px){
      margin-top: 0!important;
      }
      color: $alternate_color!important;
    }
    p{
      font-size: 1.25rem!important;
      line-height: 1.85rem!important;
      color: #FFFFFF!important;
    }
    .btn{
      span{
        @media(max-width: 767px){
          font-size: .85rem!important;
        }
      }
    }
  }
  #documentLinks{
    .btn{
      span{
        padding: 17px 14px 12px 14px;
      }
    }
    @media(max-width: 767px){
      margin-top: 10px;
      .btn{
        width: 100%;
      }
    }
  }
  .category{
    display: block;
    color: $light_blue;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  .applications, .features{
    h4{
      color: $primary_color;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 900;
    }
    ul{
      margin-left: 15px;
      padding-left: 0;
    }
  }
  .featured-projects{
    background-color: transparent;
  }
  .carousel{
    .carousel-buttons{
      position: absolute;
      bottom: 0;
      right: 0;
      .btn:active{
        background-image: none!important;
        box-shadow: none!important;
      }
    }
  }
}

.vertical-staff-card{
  h4{
    font-size: 1.3rem;
    font-weight: 900;
    margin-bottom: 10px;
  }
  h3{
    font-size: 1.7rem;
    font-weight: 900;
    color: $primary_color!important;
  }
  i{
    color: $light_blue;
    font-size: 2.1rem;
  }
  img{
    margin: auto;
  }
}

.blog-post{
  h2{
    color: $primary_color!important;
    font-size: 1.6rem;
  }
  .read-more{
    color: $light_blue;
    font-weight: 700;
    i{
      font-size: 1.6rem;
      line-height: 1.1;
    }
  }
}
.base-footer{
  p{
    @media(max-width: 767px){
      text-align: center;
    }
  }
  .footer-nav{
    @media(min-width: 992px){
      margin-right: 200px;
      margin-top: 15px;
    }
    @media(max-width: 991px){
      width: 100%;
    }
    @media(max-width: 767px){
      float: none;
    }
    li{
      @media(max-width: 991px){
        display: block;
        margin-top: 15px;
        width: 100%;
        margin-left: 0;
      }
      @media(max-width: 767px){
        text-align: center;
      }
      a{
        color: $alternate_color;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
        border-bottom: 0;
        padding: 0 25px;
          @media(max-width: 991px){
            padding: 0;
          }
      }
    }
  }
  #footerCTA{
    @media(max-width: 767px){
      text-align: center;
    }
    @media(min-width: 992px){
      float: right!important;
      position: absolute;
      right: 0px;
    }
    @media(max-width: 991px){
      display: block;
      margin-top: 15px;
      position: relative;
      right: 0;
      top: 15px;

    }
  }
}
.footer-logo{
  display: block;
  margin-bottom: 25px;
  @media(max-width: 767px){
    .logo{
      margin: auto;
    }
  }

}
