@import "_variables";
.header-v1{
/* DESKTOP NAV */
  background-color: #FFFFFF!important;
  padding: 0px 0 10px 0!important;
@media(min-width:1001px){
  .main-navigation{
    list-style-type: none;
    margin-bottom: 0;
      li{
        &:hover{
          .sub-menu {
          }
        }
        a{
          position: relative;
          display: inline-block;
          color: $alternate_color;
          font-weight: 600;
          padding: 15px 15px;
          font-size: 1rem;
          text-transform: uppercase;
          &:hover{
            text-decoration: none;
            color: $primary_color;
            cursor: pointer;
          }
        }
      }
      .sub-menu {
          display: none;
          transition: all ease-in-out 0.25s;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 200px;
          padding-left: 0;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 1;
          li{
            width: 100%;
            display: block;
            a{
              width: 100%;
              padding: 5px 15px;
              color: #202020;
              font-size: 1.3rem;
              &:hover{
                color: $primary_color;
              }
            }
          }
      }
  }
}

&.sticky{

  &.stuck{
    box-shadow: none!important;

    .main-navigation{
      li{
        a{
          @media(min-width: 1001px){
            color: $alternate_color;
          }
        }
      }
      .sub-menu{
        li{
          a{
            color: $body_text_color;
            &:hover{
              color: $primary_color;
            }
          }
        }
      }
    }

  }
}

/* MOBILE NAV */
.button-container {
  top:47px;
  @media(min-width:768px) and (max-width:991px){
    top:62px;
  }

  .fa{

  }
  &.left{

  }
  &.right{

  }

  &#toggle-close{

    .fa{
    color: #202020!important;
    }
  }
}

.overlay-menu-container {
  position: fixed;
  background: #FFFFFF!important;
  visibility: hidden;
  overflow: hidden;
  padding: 10px 0;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;

  nav {
    overflow-x: scroll;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    li {
      display: block;
      position: relative;
      background: rgba(255,255,255,0.3);


      a {
        display: block;
        position: relative;
        color: $alternate_color!important;
        font-size: 1rem;
        padding: 10px;
        text-decoration: none;
        overflow: hidden;
        font-weight: bold;
        border-bottom: solid 1px #e0e0e0;
        border-top: solid 1px #e0e0e0;

      }
      .sub-menu{
        display: none;
        -webkit-box-shadow: 0 15px 22px -13px rgba(0,0,0,.6);
        -moz-box-shadow: 0 15px 22px -13px rgba(0,0,0,.6);
        box-shadow: 0 15px 22px -13px rgba(0,0,0,.6);
        width: 100%;
        li{
          padding: 5px 5px 5px 15px;
          border-top:none;
          border-bottom: none;
          a{
            padding: 5px;
            font-weight: bold;
            border-top:none;
            border-bottom: none;
          }
        }
      }
    }
  }

  .cta-buttons{
    margin:2rem 0;
  }
}
  .action-buttons{
    margin-bottom: 10px;
  }

}
